import React from 'react';

const ProductList = ({ products, addToCart, showAddToCartButton }) => {
  return (
    <div className="product-list">
      {products.map(product => (
        <div key={product.id} className="product-item">
          <div className="item">
            <img src={product.image} alt={product.name} />
          </div>
          <div>
            <h3>{product.name}</h3>
            <p>Price: ₹{product.price}</p>
            {showAddToCartButton && false && (
              <button onClick={() => addToCart(product)}>Add to Cart</button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductList;
