import React, { useState, useRef, useEffect } from "react";

const Navbar = ({ categories, selectedCategory, onCategoryChange }) => {
  const [isMobile, setIsMobile] = useState(false);
  const scrollContainer = useRef(null);

  // Responsive behavior
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Scroll function
  const scroll = (direction) => {
    if (scrollContainer.current) {
      const scrollAmount = direction === "left" ? -200 : 200;
      scrollContainer.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  // Drag-to-scroll functionality
  useEffect(() => {
    if (scrollContainer.current) {
      const container = scrollContainer.current;
      let isDragging = false;
      let startX, scrollLeft;

      const onMouseDown = (e) => {
        isDragging = true;
        startX = e.pageX - container.offsetLeft;
        scrollLeft = container.scrollLeft;
      };
      const onMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - container.offsetLeft;
        const walk = x - startX;
        container.scrollLeft = scrollLeft - walk;
      };
      const onMouseUp = () => (isDragging = false);

      container.addEventListener("mousedown", onMouseDown);
      container.addEventListener("mousemove", onMouseMove);
      container.addEventListener("mouseup", onMouseUp);
      container.addEventListener("mouseleave", onMouseUp);

      return () => {
        container.removeEventListener("mousedown", onMouseDown);
        container.removeEventListener("mousemove", onMouseMove);
        container.removeEventListener("mouseup", onMouseUp);
        container.removeEventListener("mouseleave", onMouseUp);
      };
    }
  }, []);

  return (
    <div className="navbar-container">
      {isMobile ? (
        <>
          <select
            className="navbar-dropdown"
            value={selectedCategory || "All"}
            onChange={(e) => onCategoryChange(e.target.value)}
          >
            <option value="All">All Categories</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
          <button className="reset-button" onClick={() => onCategoryChange("All")}>
            Reset
          </button>
        </>
      ) : (
        <>
          <div className="navbar">
            <button className="scroll-arrow left-arrow" onClick={() => scroll("left")}>
              &#8592;
            </button>
            <div className="navbar-scroll" ref={scrollContainer}>
              {categories.map((category) => (
                <button
                  key={category}
                  className={`navbar-button ${selectedCategory === category ? "active" : ""}`}
                  onClick={() => onCategoryChange(category)}
                >
                  {category}
                </button>
              ))}
            </div>
            <button className="scroll-arrow right-arrow" onClick={() => scroll("right")}>
              &#8594;
            </button>
          </div>
          <div className="reset-container">
            <img src="./icons/reset.svg" alt="Reset" />
            <button className="reset-button" onClick={() => onCategoryChange("All")}>
              Reset
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Navbar;
