import React from 'react';
import '../stylesheet/about.css'

const About = () => {
  return (
    <div className="about-us">
      <div className='commonBanner'>About Us</div>
      <section className="hero container">
        <h1>Welcome to Hotel Cine Vijayam</h1>
        <p>Experience the taste of traditional flavors in every bite.</p>
      </section>

      <section className="mission container">
        <div className="content">
          <div className='vijayamess'><img src="../restaurant.png" /></div>
         <div className='vijayamessabt'> 
          <h2>Traditional & <br/> Modern Food</h2>
          <p>At Hotel Cine Vijayam, we are dedicated to bringing the finest, authentic Indian cuisine to our guests.
            Our mission is to create unforgettable dining experiences that bring people together and celebrate the rich culinary heritage of India.</p>
            <button>Our Menu</button>
            </div>
            <div className='vijayamess'><img src="../chef.jpg" /></div>
        </div>
      </section>

      <section className="know container">
        <div><img src='../about.jpg'/></div>
        <div className='knowmore'>
          <div >
          <h3>OUR STORY</h3>
          <h1>Where Great Food meets Good Vibes</h1>
          <p>Cine vijayamess was started in 2000 by "Person Name". After days it became everyones favourate restaurant of all time.
            Where every meal is a story of flavor, crafted with passion and served with warmth.</p>
          </div>
          <div className='knowcontent'>
            <div>
              <h1>10+</h1>
              <h3>Experience</h3>
            </div>
            <div>
              <h1>30+</h1>
              <h3>Employees</h3>
            </div>
          </div>
        </div>
      </section>

      <section className="chef-spotlight container">
        <h2>Meet Our Chef</h2>
        <div className="chef-container">
          <img src="chef.jpg" alt="Chef" />
          <p>Chef Arjun Kumar brings decades of culinary expertise and passion to every dish. Known for his innovative techniques and respect for tradition, Chef Arjun is the heart and soul of Hotel Cine Vijayam’s kitchen.</p>
        </div>
      </section>

      <section class="reviews-section container">
    <h2 class="mainfont">What Our Customers Say</h2>
    <div class="reviews-container">
      <div class="review-card">
        <img src="../testimonials/pp1.jpg" alt="Customer 1" class="review-photo" />
        <h3 class="review-name">Selvakumar Baskararajan</h3>
        <p class="review-text">"The 4 star rating is for the service which a working woman in the restaurant had given for a mother who cam with child, which has taken care by the woman working in the restaurant. Food is normal, service is not ok. The food orders were spilled in the table while serving and spilled in the tray which they carry and collect all place in our plate to serve us."</p>
        <div class="review-stars">⭐⭐⭐⭐⭐</div>
      </div>


      <div class="review-card">
        <img src="../testimonials/pp2.jpg" alt="Customer 2" class="review-photo" />
        <h3 class="review-name">Yogesh VS</h3>
        <p class="review-text">"Their food wa average comparing to other hotel they should must have non A/C dining area their sevice was very slow because lack of service peoples smbience is very good"</p>
        <div class="review-stars">⭐⭐⭐⭐⭐</div>
      </div>


      <div class="review-card">
        <img src="../testimonials/pp3.jpg" alt="Customer 3" class="review-photo" />
        <h3 class="review-name">Arjun S</h3>
        <p class="review-text">"Tasty. Low on pocket... Reliable
<br/>
A very great place if you want to hang out with friends and family to spend some quality time. The food is very delicious here, plus they have o much variety. The price is also affordable. But the service is bad and take away experience will also be bad. The place will be not that much crowded."</p>
        <div class="review-stars">⭐⭐⭐⭐⭐</div>
      </div>

      <div class="review-card">
        <img src="../testimonials/pp4.jpg" alt="Customer 4" class="review-photo" />
        <h3 class="review-name">shenbaga rajan</h3>
        <p class="review-text">"Nice experience here for Dinner with friends. Ambience was really gud with warm lighting. Suppliers are really fast with the ordera and owner was really friendly with everyones and takes suggestion."</p>
        <div class="review-stars">⭐⭐⭐⭐⭐</div>
      </div>
    </div>
  </section>
    </div>
  );
};

export default About;
