import React from "react";
import "../index.css";
import "../stylesheet/home.css";
import BanCarousel from "../additional/bancarousel";
import Hotelfood from "../additional/Hotelfood";
import Catering from "../additional/Catering";

const Home = () => {
  return (
    <div>
      <div>
        <BanCarousel />

        <div className="penguin container">
          <div className="workers">
            <img src="../icons/chef.png" />
            <h2>Chef’s Mastery</h2>
            <p>
              Crafted with passion, every dish tells a story. Our chef brings
              flavors to life with expertise and love for the craft.
            </p>
          </div>
          <div className="workers">
            <img src="../icons/delivery.png" />
            <h2>Chef’s Mastery</h2>
            <p>
              Crafted with passion, every dish tells a story. Our chef brings
              flavors to life with expertise and love for the craft.
            </p>
          </div>
          <div className="workers">
            <img src="../icons/serving.png" />
            <h2>Chef’s Mastery</h2>
            <p>
              Crafted with passion, every dish tells a story. Our chef brings
              flavors to life with expertise and love for the craft.
            </p>
          </div>
        </div>

        <Hotelfood />

        <div className="offerSection container">
          <div
            className="offers"
            style={{
              backgroundImage:
                "linear-gradient(45deg, #000000, #00000010), url('product/offers/offer_1.png')",
            }}
          >
            <h4>Combo Offer</h4>
            <h2>Buy 10 Parotta with Chicken Gravy</h2>
            <p>Get Ready to sink into the best Taste Ever</p>
            <h1>₹200</h1>
            <button>Order Now</button>
          </div>
          <div
            className="offers"
            style={{
              backgroundImage:
                "linear-gradient(45deg, #000000, #00000090, #00000010), url('product/offers/offer_2.png')",
            }}
          >
            <h4>Combo Offer</h4>
            <h2>Buy 10 Parotta with Chicken Gravy</h2>
            <p>Get Ready to sink into the best Taste Ever</p>
            <h1>₹200</h1>
            <button>Order Now</button>
          </div>
        </div>
        <Catering />

        <section class="reviews-section">
          <h2 class="mainfont">What Our Customers Say</h2>
          <div class="reviews-container">
            <div class="review-card">
              <img
                src="../testimonials/pp1.jpg"
                alt="Customer 1"
                class="review-photo"
              />
              <h3 class="review-name">Selvakumar Baskararajan</h3>
              <p class="review-text">
                "The 4 star rating is for the service which a working woman in
                the restaurant had given for a mother who cam with child, which
                has taken care by the woman working in the restaurant. Food is
                normal, service is not ok. The food orders were spilled in the
                table while serving and spilled in the tray which they carry and
                collect all place in our plate to serve us."
              </p>
              <div class="review-stars">⭐⭐⭐⭐⭐</div>
            </div>

            <div class="review-card">
              <img
                src="../testimonials/pp2.jpg"
                alt="Customer 2"
                class="review-photo"
              />
              <h3 class="review-name">Yogesh VS</h3>
              <p class="review-text">
                "Their food wa average comparing to other hotel they should must
                have non A/C dining area their sevice was very slow because lack
                of service peoples smbience is very good"
              </p>
              <div class="review-stars">⭐⭐⭐⭐⭐</div>
            </div>

            <div class="review-card">
              <img
                src="../testimonials/pp3.jpg"
                alt="Customer 3"
                class="review-photo"
              />
              <h3 class="review-name">Arjun S</h3>
              <p class="review-text">
                "Tasty. Low on pocket... Reliable
                <br />A very great place if you want to hang out with friends
                and family to spend some quality time. The food is very
                delicious here, plus they have o much variety. The price is also
                affordable. But the service is bad and take away experience will
                also be bad. The place will be not that much crowded."
              </p>
              <div class="review-stars">⭐⭐⭐⭐⭐</div>
            </div>

            <div class="review-card">
              <img
                src="../testimonials/pp4.jpg"
                alt="Customer 4"
                class="review-photo"
              />
              <h3 class="review-name">shenbaga rajan</h3>
              <p class="review-text">
                "Nice experience here for Dinner with friends. Ambience was
                really gud with warm lighting. Suppliers are really fast with
                the ordera and owner was really friendly with everyones and
                takes suggestion."
              </p>
              <div class="review-stars">⭐⭐⭐⭐⭐</div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home;
